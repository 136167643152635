export type Toast = {
  key: string,
  title: string,
  text: string,
  type?: 'error' | 'warning' | 'message' | 'success',
  duration?: number
}

export const useToastStore = defineStore('toastStore', () => {
  const list = ref<Toast[]>([])

  const toast = (
    toast: Toast
  ) => {
    list.value = [...list.value, toast]
    setTimeout(() => unToast(toast.key), toast.duration ?? 5000)
  }

  const unToast = (key: string) =>
    list.value = list.value.filter(item => item.key != key)

  return {
    list,
    toast,
    unToast
  }
})
